<template>
  <div class="about">
    <div class="about_main">
      <img class="full-bg" src="../../assets/image/about/about_bg.png" alt="" />
      <div class="title_img">
        <img src="@/assets/image/about/about_title.png" alt="" />
      </div>
      <div class="title_warp">
        <span>关于我们</span>
      </div>

      <div class="about_wrap">
        <div class="introduce">
          <div class="face">
            <img src="@/assets/image/about/about_face.png" alt="" />
          </div>
          <div class="intro_text">
            {{ info }}
          </div>
        </div>
        <div class="shape">
          <img src="@/assets/image/about/about_shape.png" alt="" />
        </div>
        <div class="lat">
          <img src="@/assets/image/about/about_lat.png" alt="" />
        </div>
      </div>

      <div class="about_bottom">
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @mouseenter="mouseenter(item)"
          @mouseleave="mouseleave(item)"
          @click="goToDetail()"
        >
          <img v-if="!item.hover" :src="item.img" alt="" />
          <img v-else :src="item.imghover" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAboutUs } from "@/apis";
export default {
  name: "about",
  data() {
    return {
      list: [
        {
          img: require("@/assets/image/about/bottom_01.png"),
          imghover: require("@/assets/image/about/bottom_01_h.png"),
          hover: false,
        },
        {
          img: require("@/assets/image/about/bottom_02.png"),
          imghover: require("@/assets/image/about/bottom_02_h.png"),
          hover: false,
        },
        {
          img: require("@/assets/image/about/bottom_03.png"),
          imghover: require("@/assets/image/about/bottom_03_h.png"),
          hover: false,
        },
      ],
      info: "",
    };
  },
  components: {},
  created() {
    this.getAboutInfo();
  },
  methods: {
    getAboutInfo() {
      getAboutUs().then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.info = res.data;
        }
      });
    },
    mouseenter(item) {
      item.hover = !item.hover;
    },
    mouseleave(item) {
      item.hover = !item.hover;
    },
    goToDetail() {
      this.$router.push({
        path: "/detail/aboutDetail",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.about {
  // width: 100%;
  // height: 100vh;
  // background: url("../../assets/image/about/about_bg.png") center center
  //   no-repeat;
  // background-size: 2035px 100%;
  position: relative;
}

.about_main {
  width: 80%;
  height: 80%;
  top: 3vh;
  position: relative;
  margin: 0 auto;

  .full-bg {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.title_img {
  margin-left: 30px;
  padding-top: 25px;
  img{
  height: 55px;
  width: auto;
}
}

.title_warp {
  margin-left: 30px;
  padding-top: 25px;
  
  font-family: PingFangSC-Regular, PingFang SC;
  color: #ffffff;
  line-height: 40px;
  letter-spacing: 16px;
  span{
    font-size: 30px;
  }
}

.about_wrap {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 291px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .introduce {
    width: 820px;
    height: 291px;
    background: url("../../assets/image/about/about_text_bg.png") center center
      no-repeat;
    background-size: 100% 100%;
    margin-left: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .face {
      width: 32px;
      height: 32px;
      margin-left: 31px;
      margin-right: 28px;
    }

    .intro_text {
      width: 651px;
      height: 181px;
      font-size: 20px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #ffffff;
      line-height: 45px;
    }
  }

  .shape {
    width: 350px;
    height: 100%;
    margin-left: 103px;
  }

  .lat {
    width: 106px;
    height: 40px;
    position: relative;
    left: 10px;
  }
}

.about_bottom {
  position: absolute;
  left: 77px;
  bottom: 42px;
  height: 147px;
  display: flex;
  justify-content: flex-start;

  .item {
    margin-right: 107px;
    width: 147px;
    height: 147px;
  }
}
</style>
