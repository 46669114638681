<template>
  <div class="work">
    <div class="inner-content">
      <img src="../../assets/image/works/bg.png" class="full-bg" />
      <div class="title_warp">
        <div class="title_img">
          <img src="../../assets/image/works/title.png" alt="" />
        </div>
        <div class="s-title">
          <span>优秀作品</span>
        </div>
      </div>
      <div class="main-content" id="main-content">
        <div class="left-info" v-if="list[active]">
          <div class="desc">
            <p class="en-name">{{ list[active].authorName }}</p>
            <div class="line">
              <img src="../../assets/image/works/circle.png" alt="" />
              <!-- <span>xxxxxxxxxxxxxxxxxxxx</span> -->
            </div>
            <p class="desc-content" v-html="list[active].personalIntro"></p>
          </div>
          <img src="../../assets/image/works/hooks.png" class="hooks" />
        </div>
        <div class="video-part" v-if="list[active]">
          <!-- video -->
          <template v-if="list[active].resourceType == 2">
            <video
              class="video"
              id="video"
              controls
              :poster="list[active].coverUrl"
              :src="list[active].pictureUrl"
            ></video>
            <img
              src="../../assets/image/works/play.png"
              v-if="!isPlay"
              @click="play()"
              class="play"
            />
          </template>
          <!-- image -->
          <img v-else :src="list[active].pictureUrl" class="show-img" />
          <img src="../../assets/image/works/video_bg.jpg" class="bg" />
        </div>
        <img
          src="../../assets/image/works/arrow-right.png"
          class="arrow-right"
          @click="handleDetail"
        />
        <img
          src="../../assets/image/works/number-right.png"
          class="number-right"
        />
      </div>
      <div class="bottom">
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          :class="{ active: index == active }"
          @click="handleClickItem(index)"
        >
          <p class="name">{{ item.authorName }}</p>
        </div>
      </div>
    </div>
    <BottomPoint class="bottom-point" />
  </div>
</template>

<script>
import BottomPoint from "@/components/BottomPoint.vue";
import { worksList } from "@/apis";
export default {
  name: "about",
  components: {
    BottomPoint,
  },
  data() {
    return {
      video: null,
      isPlay: false,
      active: 0,
      list: [],
    };
  },
  methods: {
    handleClickItem(index) {
      this.active = index;
      console.log("===================", this.list[index].resourceType);
      if (this.list[index].resourceType == 2) {
        this.$nextTick(() => {
          this.addListenVideo();
        });
      }
    },
    play() {
      this.video.play();
    },
    getData() {
      worksList().then((res) => {
        const { data } = res;
        this.list = data;
        this.$nextTick(() => {
          this.handleClickItem(0);
        });
      });
    },
    addListenVideo() {
      if (this.video) {
        this.video.removeEventListener("play");
        this.video.removeEventListener("pause");
      } else {
        this.video = document.getElementById("video");
      }
      this.video.addEventListener("play", () => {
        //暂停开始执行的函数
        this.isPlay = true;
      });
      this.video.addEventListener("pause", () => {
        //暂停开始执行的函数
        this.isPlay = false;
      });
    },
    handleDetail() {
      this.$router.push({
        path: "/detail/worksDetail",
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="less" scoped>
.work {
  position: relative;
  width: 100%;
}

.inner-content {
  width: 80%;
  height: 78%;
  top: 0vh;
  position: relative;
  margin: 0 auto;
}

.full-bg {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  margin: auto;
}

.title_warp {
  margin-left: 20px;
  padding-top: 30px;
  font-size: 19px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 27px;
  letter-spacing: 8px;

  .title_img {
    margin-left: 0%;
    height: 65px;
    width: 1449px;
  }
  .s-title {
    margin-top: 30px;
    span {
      font-size: 30px;
      letter-spacing: 16px;
    }
  }
}

.main-content {
  height: 60%;
  margin: 0 auto;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: relative;
  font-family: PingFangSC-Regular, PingFang SC;
  z-index: 16;

  .left-info {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0px;
    z-index: 15;
    .desc {
      .en-name {
        font-size: 40px;
        color: #fff;
      }
      .line {
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        display: flex;
        align-items: center;
        img {
          width: 44px;
          margin-right: 12px;
        }
        span {
          font-size: 20px;
          color: #fff;
        }
      }
      .desc-content {
        // display: block;
        color: #fff;
        font-size: 16px;
        width: 300px;
        text-align: left;
        margin-top: 10px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 8;
        overflow: hidden;
        cursor: pointer;
      }
    }
    .hooks {
      width: 220px;
      margin-left: 10px;
    }
  }
  .video-part {
    width: 60%;
    height: 90%;
    position: relative;
    margin: 0 auto;
    z-index: 14;
    .video,
    .show-img {
      position: absolute;
      width: auto;
      width: 70%;
      height: 85%;
      left: 5%;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    .bg {
      width: 80%;
      margin-left: 120px;
    }
    .play {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 5%;
      margin: auto;
      z-index: 2;
      width: 120px;
      height: 120px;
      transition: 0.3s;
      animation: toBig 2s infinite;
      cursor: pointer;
    }
  }
  .arrow-right {
    position: absolute;
    right: 200px;
    width: 143px;
    height: 27px;
    animation: toLeft 2s infinite;
    cursor: pointer;
  }
  .number-right {
    position: absolute;
    top: 20.5vh;
    right: 0px;
    width: 96px;
    height: 37px;
  }
}
.bottom {
  position: absolute;
  bottom: -5px;
  width: 46%;
  left: 50%;
  margin-left: -23%;
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 13;
  .item {
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
    &::after {
      content: "";
      background: #475266;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      display: block;
      transition: all 0.3s;
    }
    .name {
      font-size: 20px;
      color: #fff;
      transform: translate(-35px, -120px) rotate(-60deg);
      transform-origin: center bottom;
      transition: all 0.3s;
      font-family: "Bedstead";
      position: absolute;
      width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.active {
      &::before {
        content: "";
        background-image: url("../../assets/image/works/light.png");
        width: 217px;
        height: 300px;
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        left: 0;
        top: 0;
        transform: translate(-20px, -300px);
      }
      &::after {
        background-image: linear-gradient(#befffa, #8afff2);
        width: 20px;
        height: 20px;
      }
      .name {
        font-size: 30px;
        transform: translate(-25px, -140px) rotate(-60deg);
      }
    }
  }
}

.bottom-point {
  // position: absolute;
  // bottom: 4%;
  margin-top: 2%;
}

@keyframes toBig {
  50% {
    transform: scale(1.2);
  }
}

@-webkit-keyframes toBig {
  50% {
    transform: scale(1.2);
  }
}

@keyframes toLeft {
  50% {
    transform: translateX(40px);
  }
}

@-webkit-keyframes toLeft {
  50% {
    transform: translateX(40px);
  }
}
</style>
