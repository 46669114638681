<template>
  <div id="first">
    <Header :active="active" />
    <full-page  :options="options" ref="fullpage" id="fullpage">
      <home class="section"></home>
      <about class="section"></about>
      <course class="section"></course>
      <works class="section"></works>
      <star class="section"></star>
      <conact class="section"></conact>
    </full-page>
    <!-- <div class="beian">备案号：浙B2-20120091-4</div> -->
    <div class="container-fluid">
      <div class="square0"></div>
      <div class="square1"></div>
      <div class="square2"></div>
      <h1></h1>
    </div>
  </div>
</template>
  
<script>
import Header from "@/components/Header.vue";
import star from "@/views/star/index.vue";
import home from "@/views/home/Home.vue";
import conact from "@/views/conact/index.vue";
import about from "@/views/about/index.vue";
import course from "@/views/course/index.vue";
import works from "@/views/works/index.vue";
export default {
  name: "index",
  components: {
    Header,
    home,
    conact,
    star,
    about,
    course,
    works,
  },
  data() {
    return {
      active:0,
      options: {
        licenseKey: "K87VH-WN6L7-HW2J9-HE0IH-EAYLM",
        //是否显示导航，默认为false
        navigation: false,
        // afterLoad: this.afterLoad,

        // anchors: ["page1", "page2", "page3", "page4", "page5", "page6"],
        scrollOverflow: false,
        // sectionsColor: ["#12151A", "#12151A", "#12151A", "#12151A"],
        onLeave:(origin, destination)=>{
          this.active = destination.index;
        }
      },
      fixStyle: {},
    };
  },
  mounted() {
    if (this.$route.query && this.$route.query.scroll) {
      // 滚动到第几页，第几个幻灯片；页面从1计算，幻灯片从0计算
      this.$refs.fullpage.api.moveTo(6, 5);
    }
    // 屏幕自适应
    // 获取屏幕宽高，根据不同屏幕调整视频的宽高自适应大小
    window.onresize = () => {
      const windowWidth = document.body.clientWidth;
      const windowHeight = document.body.clientHeight;
      const windowAspectRatio = windowHeight / windowWidth;
      let videoWidth;
      let videoHeight;
      if (windowAspectRatio < 0.5625) {
        videoWidth = windowWidth;
        videoHeight = videoWidth * 0.5625;
        this.fixStyle = {
          height: windowWidth * 0.5625 + "px",
          width: windowWidth + "px",
          "margin-bottom": (windowHeight - videoHeight) / 2 + "px",
          "margin-left": "initial",
        };
      } else {
        videoHeight = windowHeight;
        videoWidth = videoHeight / 0.5625;
        this.fixStyle = {
          height: windowHeight + "px",
          width: windowHeight / 0.5625 + "px",
          "margin-left": (windowWidth - videoWidth) / 2 + "px",
          "margin-bottom": "initial",
        };
      }
    };
  },
};
</script>
  
<style lang="less" scoped>
.video_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #12151a;
}

@font-face {
  font-family: "Bedstead";
  src: url("../assets/fonts/bedstead.woff") format("woff"),
    /* chrome、firefox */ url("../assets/fonts/bedstead.ttf") format("truetype"); /* chrome、firefox、opera、Safari, Android, iOS 4.2+ */
}
.container-fluid {
  height: 100vh;
  background: #000;
  text-align: center;
  padding-top: 40vh;
  overflow: hidden;
}
.square0 {
  height: 3px;
  width: 80%;
  position: absolute;
  left: 25vw;
  top: 5vh;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 50px 50px 50px #4d564a, -50px -50px 50px #645959;
  animation-delay: 2.5s;
  opacity: 0;
  animation: interference2 4s infinite reverse;
}
.square1 {
  height: 3px;
  width: 80%;
  position: absolute;
  right: 25vw;
  bottom: 5vh;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 50px 50px 50px #4d564a, -50px -50px 50px #645959;
  animation-delay: 1.5s;
  opacity: 0;
  animation: interference2 5s infinite reverse;
}
.square2 {
  height: 5px;
  width: 80%;
  position: absolute;
  right: 15vw;
  bottom: 35vh;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 50px 50px 50px #4d564a, -50px -50px 50px #645959;
  animation-delay: 2.5s;
  opacity: 0;
  animation: interference2 4s infinite reverse;
}
h1 {
  color: #fff;
  font-size: 10em;
  animation: interference 4s infinite;
}
@keyframes interference {
  0% {
    transform: skewX(0);
  }

  31% {
    transform: skewX(-2deg);
  }

  31.5% {
    transform: skewX(89deg);
  }

  32% {
    transform: skewX(89deg);
  }

  32.1% {
    transform: skewX(0);
  }

  33% {
    transform: skewX(2deg);
  }

  54% {
    transform: skewX(0deg);
  }

  94% {
    transform: skewX(2deg);
  }

  95.1% {
    transform: skewX(-3deg);
  }

  95.2% {
    transform: skewX(-89deg);
  }

  95.3% {
    transform: skewX(2deg);
  }

  100% {
    transform: skewX(0);
  }
}
@keyframes interference2 {
  0% {
    opacity: 0;
    transform: skewX(0);
  }
  31% {
    opacity: 0;
    transform: skewX(-2deg);
  }
  31.5% {
    opacity: 1;
    transform: skewX(89deg);
  }

  32% {
    opacity: 0;
    transform: skewX(89deg);
  }
  32.1% {
    opacity: 1;
    transform: skewX(0);
  }
  33% {
    opacity: 0;
    transform: skewX(2deg);
  }
  54% {
    opacity: 0;
    transform: skewX(0deg);
  }
  94% {
    opacity: 0;
    transform: skewX(2deg);
  }
  95.1% {
    opacity: 1;
    transform: skewX(-53deg);
  }
  95.2% {
    opacity: 1;
    transform: skewX(-89deg);
  }
  95.3% {
    opacity: 0;
    transform: skewX(2deg);
  }
  100% {
    opacity: 0;
    transform: skewX(0);
  }
}
</style>
  