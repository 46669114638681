<template>
  <div class="header">
    <div class="logo">
      <img src="@/assets/image/logo.png" alt="" />
    </div>
    <!-- <div class="flash">
            <img src="../assets/image/flash.png"
                 alt="">
        </div> -->
    <div class="loading">
      <div class="line-dv">
        <img src="@/assets/image/heart/line-0.png" class="line"  v-show="active == 0" />
        <img src="@/assets/image/heart/line-1.png" class="line"  v-show="active == 1" />
        <img src="@/assets/image/heart/line-2.png" class="line"  v-show="active == 2" />
        <img src="@/assets/image/heart/line-3.png" class="line"  v-show="active == 3" />
         <img src="@/assets/image/heart/line-4.png" class="line"  v-show="active == 4" />
        <img src="@/assets/image/heart/line-5.png" class="line"  v-show="active == 5" />
        <img src="@/assets/image/heart/point.png" class="point" :class="'s'+active">
      </div>
      <img src="@/assets/image/heart/logoo.png" class="logoo">
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props:{
    active:{
      type:Number
    }
  },
  methods:{
    getImg(index){
      return require(`@/assets/image/heart/line-${index}.png`)
    }
  }
};
</script>

<style lang="less" scoped>
.header {
  /* position: fixed; */
  /* top: 0;                                                                                                                                                                                left: 0; */
  width: 100%;
  height: 112px;
  /* background-color: aqua; */
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #13151A;
  z-index: 20;
  position: relative;
}

.logo {
  width: 280px;
  height: 60px;
  margin-left: 10%;
  margin-bottom: 13px;
}

.flash {
  width: 521px;
  height: 65px;
  margin-right: 84px;
}

.loading {
  display: flex;
  align-items: flex-end;
  margin-right: 10%;
  position: relative;
  top: -15px;

  .line-dv{
      width: 401px;
      height: 53px;
      position: relative;
    .line{
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    .point{
      width: 20px;
      height: 20px;
      position: absolute;
      left: 70px;
      transition: all .3s;
      animation: toBig 2s infinite;

      &.s0{
      // transform: translate(0px, 0);
      left: 70px;
      top: 40px;
      }
      &.s1{
      // transform: translate(38px, -34px);
      left: 108px;
      top:7px;
      }
      &.s2{
      // transform: translate(78px,0);
      left: 145px;
      top: 40px;
      }
      &.s3{
      // transform: translate(128px,0);
      left: 205px;
      top: 40px;
      }
      &.s4{
      // transform: translate(184px,-54px);
      left: 255px;
      top: -7px;
      }
      &.s5{
      // transform: translate(235px,0);
      left: 305px;
      top: 40px;
    }
    }
  }
  .logoo{
    width: 117px;
    height: 11px;
    margin-left: 20px;
  }
}



@keyframes toBig {
        50% {
            transform: scale(2);
        }
    }

    @-webkit-keyframes toBig {
        50% {
            transform: scale(2);
        }
    }
</style>