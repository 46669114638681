<template>
  <div class="star">
    <div class="main">
      <img class="main__bg" src="../../assets/image/star/star-bg.png" />
      <div class="main__header">
        <div class="main__header--icon">
          <img src="../../assets/image/star/star-logo.png" />
        </div>
        <div class="main__header--title">
          <span>明星学员</span>
        </div>
      </div>
      <div class="img-bottom">
        <img src="@/assets/image/home/home_bottom.png" alt="" />
      </div>
      <div class="img-lat">
        <img src="@/assets/image/about/about_lat.png" alt="" />
      </div>
      <div class="point-dv">
        <div class="p-block" v-for="item in 3" :key="item">
          <div class="point mr40"></div>
          <div class="line mr10"></div>
          <div class="point mr40"></div>
          <div class="point mr10"></div>
          <div class="line mr10"></div>
          <div class="line mr10"></div>
          <div class="line mr40"></div>
          <div class="line mr10"></div>
          <div class="line mr10"></div>
          <div class="line mr40"></div>
          <div class="line mr10"></div>
          <div class="point mr10"></div>
          <div class="line mr10"></div>
          <div class="line mr40"></div>
        </div>
      </div>
    </div>
    <div class="member">
      <swiper v-if="isSwiper" :list="list" />
      <!-- <div class="all">
                <div v-for="(item, index) in list" :key="index">
                    <div
                        v-if="chooseIndex !== index"
                        :class="[item.name]"
                        @click="handleChooseIndex(index)"
                    > 
                        <img :src="item.bg" alt="" />
                        <div class="member__item--pic">
                            <img :src="item.url" alt="" />
                        </div>
                    </div>
                    <div
                        v-else
                        @click="handleChooseIndex(index)"
                        class="member__select"
                    > 
                        <img src="@/assets/image/star/member-select.png" alt="" />
                        <div class="member__item--pic">
                            <img :src="item.url" alt="" />
                        </div>
                        <div class="member-bar">
                            <img src="@/assets/image/star/icon-bar.png" alt="" />
                            <div class="member-info">
                                <div class="info-name">
                                    {{ item.realName || '' }}
                                </div>
                                <div class="info-icon">
                                    <img src="@/assets/image/star/star-name.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
    </div>
  </div>
</template>

<script>
import swiper from "@/components/swiper.vue";
import { getStarList } from "@/apis";
export default {
  name: "class",
  components: {
    swiper,
  },
  data() {
    return {
      isSwiper: false,
      chooseIndex: -1,
      mainStyle: {},
      gameActive: false,
      genActive: false,
      techActive: false,
      list: [],
    };
  },
  computed: {},
  async created() {
    const data = await this.getList();
    this.list = data;
    this.isSwiper = true;
  },
  methods: {
    getList() {
      return new Promise((resolve) => {
        getStarList().then((res) => {
          if (res.code === 200) {
            const list = res.data;
            let tempList = [];
            list.map((item) => {
              const tempItem = {
                bg: require("@/assets/image/star/member-bg1.png"),
                url: item.pictureUrl,
                name: "member__item1",
                realName: item.authorName + "/" + item.authorNameEn,
              };
              tempList.push(tempItem);
            });
            resolve(tempList);
          }
        });
      });
    },
    // 进行选中索引
    handleChooseIndex(index) {
      if (this.chooseIndex === index) {
        this.chooseIndex = -1;
        return;
      }
      this.chooseIndex = index;
    },
    handleMove(key) {
      this[key] = !this[key];
    },
    getStyle() {
      const height =
        document.getElementById("main-content").offsetHeight + "px";
      this.mainStyle = {
        height,
        width: height,
      };
    },
  },
  mounted() {
    this.getStyle();
  },
};
</script>

<style lang="less" scoped>
.star {
  position: relative;
}

.main {
  width: 80%;
  height: 77.2222%;
  top: 3vh;
  position: relative;
  margin: 0 auto;
  .main__bg {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    bottom: 0;
    // margin: auto;
  }
  .main__header {
    margin-left: 1.5%;
    padding-top: 30px;
    font-size: 35px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    letter-spacing: 16px;
    .main__header--icon {
      margin-left: 0%;
      width: 70.09%;
    }
    .main__header--title {
      margin-top: 20px;
      font-size: 30px;
   span{
    letter-spacing: 16px;
   }
    }
  }
  .img-bottom {
    width: 90%;
    position: absolute;
    bottom: 5%;
    left: 5%;
  }
  .img-lat {
    width: 96px;
    height: 37px;
    position: absolute;
    right: 0px;
    top: calc(50% - 18px);
  }
}
.point-dv {
  display: flex;
  justify-content: space-between;
  width: 96%;
  position: absolute;
  bottom: -4%;
  left: 2%;
  margin: auto;
  overflow: hidden;
  .p-block {
    display: flex;
    .point {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #404246;
    }
    .line {
      background-color: #404246;
      height: 5px;
      width: 1.5vw;
      border-radius: 10px;
      animation: highlight 2s infinite;
    }
    .mr40 {
      margin-right: 20px;
      animation-delay: 1.5s;
    }
    .mr10 {
      margin-right: 10px;
    }
  }
}
@keyframes highlight {
  from {
    background-color: #404246;
  }
  to {
    background-color: #486c6c;
  }
}
@-webkit-keyframes highlight {
  from {
    background-color: #404246;
  }
  to {
    background-color: #486c6c;
  }
}
.member {
  width: 78%;
  height: 54%;
  display: -webkit-box;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  position: absolute;
  top: 27%;
  left: 11px;
  z-index: 1111;
  left: 5%;
  .all {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 37px;
    .member__item--pic {
      position: absolute;
      z-index: 888;
      width: 70%;
      height: 70%;
      border-radius: 50%;
      overflow: hidden;
      top: 15%;
      left: 15%;
      cursor: pointer;
    }
  }
  .member__item1 {
    width: 140px;
    height: 140px;
    margin-left: 37px;
    position: relative;
  }
  .member__item2 {
    width: 170px;
    height: 170px;
    margin-left: 37px;
    position: relative;
  }
  .member__item3 {
    width: 220px;
    height: 220px;
    margin-left: 37px;
    position: relative;
  }
  .member__item4 {
    width: 260px;
    height: 260px;
    margin-left: 37px;
    position: relative;
  }
  .member__select {
    width: 350px;
    height: 350px;
    margin-left: 37px;
    position: relative;
  }
  .member-bar {
    position: absolute;
    width: 80px;
    height: 80px;
    bottom: -30px;
    right: 30%;
  }
  .member-info {
    position: absolute;
    height: 27px;
    z-index: 10086;
    bottom: -22px;
    left: 74px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .info-name {
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #272d38;
      line-height: 27px;
      height: 27px;
      background: #fff;
      padding: 0 8px;
    }
    .info-icon {
      width: 27px;
      height: 27px;
      margin-left: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    // right:;
  }
}
</style>
