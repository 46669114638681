<template>
  <div class="conact">
    <div class="conact_main">
      <img
        class="full-bg"
        src="../../assets/image/conact/conact_bg.png"
        alt=""
      />
      <div class="title_img">
        <img src="@/assets/image/conact/conact_title.png" alt="" />
      </div>
      <div class="title_warp">
        <span>联系我们</span>
      </div>

      <div class="conact_middle">
        <div class="middle_01">
          <div class="address">
            <div class="address_item">
              <span>公司：</span>
              <span>{{ info.companyName }}</span>
            </div>
            <div class="address_item">
              <span>地址：</span>
              <span>{{ info.companyAddress }}</span>
            </div>
            <div class="address_item">
              <span>联系人：</span>
              <span>{{ info.contactUser }}</span>
            </div>
            <div class="address_item">
              <span>联系方式：</span>
              <span>{{ info.contactPhone }}</span>
            </div>
            <div class="beian_wrap">备案号：<a href="https://beian.miit.gov.cn/" style="color: white;">沪ICP备2020038140号-2</a></div>
          </div>
          <div class="code_1">
            <div class="code_img">
              <img src="../../assets/image/home/wechat_kefu.png" alt="" />
            </div>
            <span>微信客服</span>
          </div>
          <div class="code_1">
            <div class="code_img">
              <img
                src="../../assets/image/home/wechat_gongzhonghao.png"
                alt=""
              />
            </div>
            <span>微信公众号</span>
          </div>
        </div>
        <div class="middle_02">
          <img src="@/assets/image/conact/conact_18.png" alt="" />
        </div>
        <div class="middle_03">
          <img src="@/assets/image/conact/conact_16.png" alt="" />
        </div>
      </div>
      <div class="bottom">
        <img src="@/assets/image/home/home_bottom.png" alt="" />
      </div>
    </div>
    <!-- <bottom-point class="bottom_wrap"></bottom-point> -->
  </div>
</template>

<script>
import { getContactUsInfo } from "@/apis";
// import bottomPoint from "@/components/BottomPoint";
export default {
  name: "conact",
  components: {
    // bottomPoint,
  },
  data() {
    return {
      info: {},
    };
  },
  created() {
    this.queryForAboutInfo();
  },
  methods: {
    queryForAboutInfo() {
      getContactUsInfo().then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.info = res.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.conact {
  // width: 100%;
  // height: 100vh;
  // background: url("../../assets/image/conact/conact_bg.png") center center
  //   no-repeat;
  // background-size: 2035px 100%;
  position: relative;
}

.conact_main {
  width: 80%;
  height: 80%;
  top: 3vh;
  position: relative;
  margin: 0 auto;

  .full-bg {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.title_img {
  margin-left: 31px;
  padding-top: 35px;
  width: 748px;
  height: 45px;
}

.title_warp {
  margin-left: 29px;
  padding-top: 35px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;

  span{
    font-size: 30px;
  letter-spacing: 16px;
  }
}

.conact_middle {
  position: absolute;
  width: 100%;
  height: 330px;
  top: 50%;
  transform: translateY(-50%);
  /* background-color: rebeccapurple; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.middle_01 {
  width: 940px;
  height: 100%;
  margin-left: 35px;
  background: url("../../assets/image/conact/address_bg.png") center center
    no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.middle_01 .address {
  width: 453px;
  height: 283px;
  // background: url("../../assets/image/conact/address_bg.png") center center
  //   no-repeat;
  // background-size: 100% 100%;
}

.address_item {
  padding-left: 57px;
  font-size: 19px;
  color: white;
  line-height: 21px;
  padding-bottom: 10px;
}

.beian_wrap {
  padding-left: 57px;
  padding-top: 20px;
  font-size: 16px;
  font-family: SourceHanSansCN-Light, SourceHanSansCN;
  font-weight: 300;
  color: #6b7b99;
  line-height: 24px;
}

.code_1 {
  width: 110px;
  height: auto;
  margin-right: 25px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 21px;
  text-align: center;

  .code_img {
    width: 110px;
    height: 110px;
    margin-bottom: 5px;
  }
}

.address_item:first-child {
  padding-top: 76px;
}

.address_item span:first-child {
  background-color: white;
  color: black;
  padding-left: 5px;
  margin-right: 5px;
}

.middle_01 .row {
  width: 143px;
  height: 27px;
  margin-left: 40px;
}

.middle_02 {
  width: 470px;
  height: 331px;
  margin-left: 164px;
}

.middle_03 {
  margin-left: 67px;
  img {
    margin-left: -10px;
    margin-top: 10px;
  width: 96px;
  height: auto;
  }
}

.bottom {
  position: absolute;
  width: 1427px;
  height: 69px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 49px;
}
</style>
