<template>
    <div class="home">
        <div class="home_main">
            <img class="full-bg"
                 src="../../assets/image/home/home_bg.png"
                 alt="" />
            <div class="title">
                <img src="@/assets/image/home/home_hit.png"
                     alt="" />
            </div>
            <div class="left-video">
                <video id="home-video"
                       controls
                       :src="homeVideo.pictureUrl"
                       :poster="homeVideo.coverUrl"></video>
                <img src="../../assets/image/works/play.png"
                     v-if="!isPlay"
                     @click="play()"
                     class="play" />
                <!-- <img src="@/assets/image/home/star/video-bg.png"
                     class="bg"> -->
            </div>
            <div class="star">
                <img src="@/assets/image/home/star/a-circle.png"
                     class="a-circle" />
                <img src="@/assets/image/home/star/b-circle.png"
                     class="b-circle" />
                <img src="@/assets/image/home/star/small-circle.png"
                     class="s-circle" />
                <div class="p1"></div>
                <div class="p2"></div>
                <div class="p3"></div>
                <div class="p4"></div>
                <!-- <img src="@/assets/image/home/home_star.png"
                     alt="" /> -->
            </div>
            <div class="bottom">
                <img src="@/assets/image/home/home_bottom.png"
                     alt="" />
            </div>
            <img src="../../assets/image/works/number-right.png"
                 class="number-right">
        </div>
    </div>
</template>

<script>
    import { homeVideo } from "@/apis/index";
    export default {
        name: "home",
        components: {
        },
        data() {
            return {
                video: null,
                isPlay: false,
                homeVideo: {}
            };
        },
        methods: {
            play() {
                this.video.play();
            },
            addListenVideo() {
                if (this.video) {
                    this.video.removeEventListener("play");
                    this.video.removeEventListener("pause");
                } else {
                    this.video = document.getElementById("home-video");
                }
                console.log('========= this.video==========', this.video);
                this.video.addEventListener("play", () => {
                    //暂停开始执行的函数
                    this.isPlay = true;
                });
                this.video.addEventListener("pause", () => {
                    //暂停开始执行的函数
                    this.isPlay = false;
                });
            },
            getData() {
                homeVideo({ picType: 7 }).then(res => {
                    this.homeVideo = res.data;
                })
            }
        },
        mounted() {
            this.getData();
            setTimeout(() => {
                this.addListenVideo()
            }, 1000);
        }
    };
</script>

<style lang="less" scoped>
    .home {
        /* width: 100%;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  height: 100vh;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  background: url("../../assets/image/home/home_bg.png") center center no-repeat;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  background-size: 2035px 100%; */
        position: relative;
    }

    .home_main {
        width: 80%;
        height: 80%;
        top: 3vh;
        position: relative;
        margin: 0 auto;

        .full-bg {
            width: 100%;
            height: 100%;
            margin: 0 auto;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    .title {
        position: absolute;
        left: 29px;
        top: 55px;
        width: 1244px;
        height: 44px;
    }

    .left-video {
        position: absolute;
        width: 800px;
        height: 320px;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        z-index: 100;
        background-color: #000;

        video {
            width: 100%;
            height: 96%;
            margin: 0 auto;
            display: block;
            z-index: 10;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            // background: #8afff2;
        }

        .play {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            z-index: 2;
            width: 120px;
            height: 120px;
            transition: 0.3s;
            animation: toBig 2s infinite;
            cursor: pointer;
            z-index: 10;
        }
    }
    .star {
        position: absolute;
        width: 380px;
        height: 401px;
        top: 50%;
        transform: translateY(-50%);
        right: 200px;
        // background: #fff;

        .a-circle {
            position: absolute;
            width: 325px;
            height: 325px;
            animation-name: circleA;
            animation-duration: 8s;
            animation-iteration-count: infinite;
        }

        .b-circle {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 260px;
            height: 258px;
            animation-name: circleB;
            animation-duration: 8s;
            animation-iteration-count: infinite;
        }
        .s-circle {
            position: absolute;
            top: 50%;
            left: 0;
            width: 60px;
            height: 60px;
            animation-name: circleB;
            animation-duration: 8.5s;
            animation-iteration-count: infinite;
            transform-origin: top;
        }
        .p1 {
            position: absolute;
            top: 153px;
            left: 56px;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: #8afff2;
            animation-name: p1;
            animation-duration: 8.5s;
            animation-iteration-count: infinite;
        }
        .p2 {
            position: absolute;
            top: 66px;
            left: 126px;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: #8afff2;
            animation-name: p2;
            animation-duration: 8.5s;
            animation-iteration-count: infinite;
        }
        .p3 {
            position: absolute;
            top: 78px;
            left: 270px;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            border: 1px solid #8afff2;
            animation-name: p3;
            animation-duration: 8.5s;
            animation-iteration-count: infinite;
        }
        .p4 {
            position: absolute;
            top: 116px;
            right: 0;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            border: 1px solid #8afff2;
            animation-name: p3;
            animation-duration: 8.5s;
            animation-iteration-count: infinite;
        }
    }

    .bottom {
        position: absolute;
        width: 1427px;
        height: 49px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 45px;
    }

    .number-right {
        position: absolute;
        top: 48%;
        right: 0px;
        width: 96px;
        height: 40px;
    }

    @keyframes circleA {
        0% {
            transform: scale(1) translateY(20px);
        }
        50% {
            transform: scale(1.1) translateY(-20px);
            opacity: 0.7;
        }
        100% {
            transform: scale(1) translateY(20px);
        }
    }

    @keyframes circleB {
        0% {
            transform: scale(1.2);
        }
        50% {
            transform: scale(1);
            opacity: 0.7;
        }
        100% {
            transform: scale(1.2);
        }
    }

    @keyframes p1 {
        0% {
            transform: scale(1.2);
            opacity: 0.12;
        }
        50% {
            transform: scale(1) translateY(-20px) translateX(-20px);
            opacity: 1;
        }
        100% {
            transform: scale(1.2);
            opacity: 0.12;
        }
    }

    @keyframes p2 {
        0% {
            transform: scale(1);
            opacity: 0.12;
        }
        50% {
            transform: scale(1.2) translateY(20px) translateX(20px);
            opacity: 1;
        }
        100% {
            transform: scale(1);
            opacity: 0.12;
        }
    }

    @keyframes p3 {
        0% {
            transform: scale(1.2) translateY(20px) translateX(20px);
            opacity: 1;
        }
        50% {
            transform: scale(1);
            opacity: 0.12;
        }
        100% {
            transform: scale(1.2) translateY(20px) translateX(20px);
            opacity: 1;
        }
    }
</style>