<template>
    <div class="conact">
        <div class="inner-content">
            <img src="../../assets/image/course/full-bg.png"
                 class="full-bg" />
            <div class="title_warp">
                <div class="title_img">
                    <img src="../../assets/image/course/title.png"
                         alt="" />
                </div>
                <div class="s-title">
                    <span>课程介绍</span>
                </div>
            </div>

            <div class="main-content"
                 id="main-content"
                 :style="mainStyle">
                <img src="../../assets/image/course/apple.png"
                     class="apple" />
                <img src="../../assets/image/course/more.png"
                     class="more"
                     @click="goToDetail(index)" />
                <img src="../../assets/image/course/shape.png"
                     class="shape" />
                <div class="circle">
                    <div class="main">
                        <div class="desc"
                             v-html="descList[active]"></div>
                    </div>
                </div>
                <div class="show">
                    <div class="item art"
                         @mouseover="handleMove('desActive')"
                         @click="goToDetail(2)">
                        <img src="../../assets/image/course/new/art.png"
                             class="origin" />
                        <img src="../../assets/image/course/new/art_1.png"
                             class="light" />
                    </div>
                    <div class="item eng"
                         @mouseover="handleMove('artActive')"
                         @click="goToDetail(1)">
                        <img src="../../assets/image/course/new/eng.png"
                             class="origin" />
                        <img src="../../assets/image/course/new/eng_1.png"
                             class="light" />
                    </div>
                    <div class="active item des"
                         @mouseover="handleMove('genActive')"
                         @click="goToDetail(0)">
                        <img src="../../assets/image/course/new/des.png"
                             class="origin" />
                        <img src="../../assets/image/course/new/des_1.png"
                             class="light" />
                    </div>
                    <!-- <div class="item game"
                         @mouseover="handleMove('gameActive')"
                         @click="goToDetail(3)">
                        <img src="../../assets/image/course/new/game.png"
                             class="origin" />
                        <img src="../../assets/image/course/new/game_1.png"
                             class="light" />
                    </div> -->
                </div>
                <!-- <div class="blank-dv">
          <div
            class="item"
            v-for="item in slist"
            :key="item"
            @mouseenter="handleMove(item)"
            @mouseout="handleMove(item)"
          ></div>
        </div> -->
            </div>
            <BottomPoint class="bottom-point" />
        </div>
    </div>
</template>

<script>
    import BottomPoint from "@/components/BottomPoint.vue";

    export default {
        name: "class",
        components: {
            BottomPoint,
        },
        data() {
            return {
                active: "gameActive",
                mainStyle: {},
                gameActive: false,
                genActive: false,
                techActive: false,
                artActive: false,
                proActive: false,
                desActive: false,
                slist: [
                    "gameActive",
                    "genActive",
                    "techActive",
                    "artActive",
                    "proActive",
                    "desActive",
                ],
                descList: {
                    gameActive:
                        "致力培养游戏制作人，熟悉游戏制作全流程，全面学习游戏制作中的各种知识。",
                    genActive:
                        "学习游戏制作中设计和程序实现，两者结合后，进阶分析动作游戏的实现过程。",
                    techActive: "学习游戏制作中设计和程序实现，在团队中充当多面手。",
                    artActive: "学习游戏制作中的程序知识，成为一个合格的游戏开发工程师。",
                    proActive:
                        "学习游戏程序和美术的知识，让学习者能够综合运用知识来解决游戏研发的技术问题，成为专业的技术美术。",
                    desActive: "学习游戏动画技术栈，成为一个合格的游戏动画设计师。",
                },
                index: 0,
            };
        },
        computed: {},
        methods: {
            handleMove(key, e) {
                console.log("===================", e);
                this[key] = !this[key];
                this.active = key;
                if (this.active === "desActive") {
                    this.index = 2;
                }
                if (this.active === "artActive") {
                    this.index = 1;
                }
                if (this.active === "genActive") {
                    this.index = 0;
                }
                if (this.active === "gameActive") {
                    this.index = 3;
                }
            },
            getStyle() {
                const height = window.innerHeight * 0.6 + "px";
                this.mainStyle = {
                    height,
                    width: height,
                };
            },
            goToDetail(index) {
                if (index === 3) {
                    window.open('https://www.9artedu.com/yangmei/pc')
                } else {
                    this.$router.push({
                        path: "/detail/courseDetail",
                        query: {
                            index,
                        },
                    });
                }
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.getStyle();
            });
            window.addEventListener("resize", () => {
                console.log("=========onresize==========");
                this.getStyle();
            });
        },
    };
</script>

<style lang="less" scoped>
    .show {
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        .origin {
            // display: none;
        }
        .light {
            display: none;
        }
        .item {
            &.des {
                position: absolute;
                top: 22%;
                left: -43%;
                width: 360px;
                .origin {
                    width: 100%;
                }
                .light {
                    width: 100%;
                    margin-top: -16%;
                    margin-left: 0%;
                }
            }
            &.eng {
                position: absolute;
                top: 57%;
                left: -47%;
                width: 360px;
                .origin {
                    width: 100%;
                }
                .light {
                    width: 100%;
                    margin-top: 0%;
                    margin-left: 0%;
                }
            }
            &.art {
                position: absolute;
                top: 22%;
                right: -31%;
                width: 260px;
                .origin {
                    width: 100%;
                }
                .light {
                    width: 100%;
                    transform: translateY(20px);
                    margin-left: 0%;
                }
            }
            &.game {
                position: absolute;
                top: 57%;
                left: 91%;
                width: 360px;
                .origin {
                    width: 100%;
                }
                .light {
                    width: 100%;
                    transform: translateY(-70px);
                    margin-left: 0%;
                }
            }
            &:hover {
                .origin {
                    display: none !important;
                }
                .light {
                    display: block !important;
                }
            }
        }
    }
    .conact {
        // background: url("../../assets/image/course/full-bg.png") center center
        //     no-repeat;
        // background-size: contain;
        position: relative;
    }

    .inner-content {
        // width: 1760px;
        // height: 975px;
        width: 80%;
        height: 80%;
        top: 3vh;
        position: relative;
        margin: 0 auto;
    }

    .full-bg {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .title_warp {
        margin-left: 30px;
        padding-top: 30px;
        font-size: 19px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 27px;
        letter-spacing: 8px;

        .title_img {
            margin-left: 0%;
            width: 90%;
        }
        .s-title {
            margin-top: 30px;
            span {
                font-size: 30px;
                letter-spacing: 16px;
            }
        }
    }

    .main-content {
        margin: 0 auto;
        margin-top: 1vh;
        border-radius: 50%;
        position: relative;

        .lh-point {
            position: absolute;
            width: 70%;
            height: 70%;
            top: 15%;
            left: 15%;

            .s-item {
                width: 10px;
                height: 10px;
                background: #8afff2;
                filter: blur(4px);
                position: absolute;
                transform-origin: center;

                &.active {
                    transform: scale(1.5);
                }
                &.gameActive {
                    top: 18%;
                    left: -3%;
                }
                &.genActive {
                    top: 50%;
                    left: -12%;
                }
                &.techActive {
                    bottom: 10%;
                    left: 4%;
                }
                &.artActive {
                    top: 18%;
                    right: -3%;
                }
                &.proActive {
                    top: 50%;
                    right: -12%;
                }
                &.desActive {
                    bottom: 10%;
                    right: 4%;
                }
            }
        }

        .apple {
            position: absolute;
            z-index: -1;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 86%;
            height: 70%;
            animation: opacity 3s infinite;
            opacity: 0.3;
        }
        .more {
            position: absolute;
            z-index: 10;
            left: 0;
            right: 0;
            bottom: 24%;
            margin: auto;
            width: 40%;
            height: 17%;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                transform: scale(1.2);
                bottom: 26%;
            }
        }

        .shape {
            position: absolute;
            z-index: -1;
            right: 150px;
            bottom: 200px;
            width: 12%;
            height: 10%;
            animation: translate 2s infinite;
            transition: all 0.3s;
            transform: translate(10px, 10px);
        }

        &::before {
            position: absolute;
            z-index: -1;
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            background-image: url('../../assets/image/course/outer-circle.png');
            background-size: cover;
            background-repeat: no-repeat;
            animation: fadenum 7s infinite;
            transform-origin: center center;
            animation-timing-function: linear;
        }

        &::after {
            position: absolute;
            z-index: -1;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            content: '';
            width: 62%;
            height: 62%;
            display: block;
            background-image: url('../../assets/image/course/inner-circle.png');
            background-size: cover;
            background-repeat: no-repeat;
            animation: fadenumm 10s infinite;
            transform-origin: center center;
            animation-timing-function: linear;
        }
        .circle {
            height: 100%;
            position: relative;
            z-index: 2;
            .main {
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                font-size: 16px;
                line-height: 30px;
                width: 40%;
                // background-color: #c10;
                margin-left: 30%;
                .desc {
                    color: #ffffff;
                    margin-top: -40px;
                    text-align: center;
                }
            }
        }
    }
    .blank-dv {
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        .item {
            position: absolute;
            width: 300px;
            height: 100px;
            &:nth-child(1) {
                top: 20%;
                left: -280px;
            }
            &:nth-child(2) {
                top: 40%;
                left: -320px;
            }
            &:nth-child(3) {
                top: 70%;
                left: -320px;
                width: 340px;
                height: 130px;
            }
            &:nth-child(4) {
                top: 20%;
                right: -320px;
            }
            &:nth-child(5) {
                top: 40%;
                right: -320px;
            }
            &:nth-child(6) {
                top: 70%;
                right: -320px;
                width: 340px;
                height: 130px;
            }
        }
    }
    .item {
        display: flex;
        color: #ffffff;
        position: absolute;
        z-index: 2;
        &::before {
            position: absolute;
            content: '';
            display: none;
        }
        .icon {
            position: absolute;
            transition: all 0.3s;
        }

        .h-icon {
            width: 109px;
            height: 110px;
            position: absolute;
            top: -20px;
            transition: all 0.3s;
            transform: scale(0);
        }
        .inner-contentt {
            display: flex;
            position: relative;
        }
        .right-dv {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            text-align: right;
            position: relative;
            color: #54575a;
            .cn {
                font-size: 20px;
            }
            .en {
                font-family: 'Bedstead';
                font-size: 20px;
                margin-top: 5%;
            }
        }

        .line {
            width: 180px;
            opacity: 0;
        }

        &.game {
            left: -250px;
            top: 24%;
            .icon {
                width: 50px;
                height: 54px;
                left: -50px;
            }
            .line {
                margin-top: -35px;
                margin-left: -45px;
                opacity: 0;
            }
        }
        &.general {
            left: -230px;
            top: 48%;
            .icon {
                width: 52px;
                height: 50px;
                left: -50px;
            }
            .line {
                margin-top: -30px;
                margin-left: -30px;
            }
        }
        &.technology {
            left: -220px;
            top: 75%;
            .icon {
                width: 50px;
                height: 58px;
                left: -50px;
            }
            .line {
                margin-top: 10px;
                margin-left: -30px;
            }
        }
        &.aritst {
            right: -350px;
            top: 24%;
            .right-dv {
                text-align: left;
            }
            .icon {
                width: 50px;
                height: 54px;
                right: -20px;
            }
            .line {
                margin-top: -35px;
                margin-left: -15px;
            }
        }
        &.project {
            right: -300px;
            top: 44.5%;
            .right-dv {
                text-align: left;
            }
            .icon {
                width: 50px;
                height: 48px;
                right: -20px;
            }
            .line {
                margin-left: -15px;
                margin-top: -10px;
            }
        }
        &.study {
            right: -280px;
            top: 75%;
            .right-dv {
                text-align: left;
            }
            .icon {
                width: 50px;
                height: 58px;
                right: -40px;
            }
            .line {
                margin-left: -15px;
                margin-top: 10px;
            }
        }
        &:hover {
            cursor: pointer;
        }
        &.active {
            .h-icon {
                transform: scale(1.1);
            }
            .line {
                opacity: 1;
            }
            .cn {
                color: #8afff2;
                font-size: 26px;
            }
            .en {
                color: #fff;
                font-size: 16px;
            }
            .icon {
                display: none;
            }

            &.game {
                .inner-contentt {
                    top: -40px;
                    left: -40px;
                }
                .h-icon {
                    left: -40px;
                }
            }
            &.general {
                .inner-contentt {
                    top: -40px;
                    left: -25px;
                }
                .h-icon {
                    left: -50px;
                }
            }
            &.technology {
                .inner-contentt {
                    top: 40px;
                    left: -25px;
                }
                .h-icon {
                    left: -50px;
                }
            }
            &.aritst {
                .inner-contentt {
                    top: -50px;
                    left: -5px;
                }
                .h-icon {
                    right: -50px;
                }
            }
            &.project {
                .inner-contentt {
                    top: -20px;
                    left: 0px;
                }
                .h-icon {
                    right: -50px;
                }
            }
            &.study {
                .inner-contentt {
                    top: 42px;
                    left: -10px;
                }
                .h-icon {
                    right: -50px;
                }
            }
        }
    }

    .bottom-point {
        position: absolute;
        bottom: 2%;
    }

    @keyframes highlight {
        from {
            background-color: #404246;
        }
        to {
            background-color: #486c6c;
        }
    }
    @-webkit-keyframes highlight {
        from {
            background-color: #404246;
        }
        to {
            background-color: #486c6c;
        }
    }

    @keyframes fadenum {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes fadenum {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes fadenumm {
        from {
            transform: rotate(360deg);
        }
        to {
            transform: rotate(0deg);
        }
    }

    @-webkit-keyframes fadenumm {
        from {
            transform: rotate(360deg);
        }
        to {
            transform: rotate(0deg);
        }
    }

    @keyframes opacity {
        50% {
            opacity: 1;
        }
    }

    @-webkit-keyframes opacity {
        50% {
            opacity: 1;
        }
    }

    @keyframes translate {
        50% {
            transform: translate(0, 0);
        }
    }

    @-webkit-keyframes translate {
        50% {
            transform: translate(0, 0);
        }
    }
</style>
