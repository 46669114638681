<template>
  <swiper :options="swiperOption" ref="mySwiper">
    <swiper-slide
      class="member__select"
      v-for="(item, index) in list"
      :key="index"
      :data-item="item"
    >
      <!-- <img src="@/assets/image/star/member-bg4.png" alt="" /> -->
      <div class="member__item--pic">
        <img :src="item.url" alt="" />
      </div>
      <div class="member-bar">
        <img src="@/assets/image/star/icon-bar.png" alt="" />
        <div class="member-info">
          <div class="info-name">
            {{ item.realName || "" }}
          </div>
          <div class="info-icon">
            <img src="@/assets/image/star/star-name.png" alt="" />
          </div>
        </div>
      </div>
    </swiper-slide>
    <!-- <div class="swiper-scrollbar" slot="scrollbar" v-show="list.length > 1"></div> -->
  </swiper>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    list: {
      type: Array,
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
      swiperOption(){
        return {
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false // 当用户滑动图片后继续自动轮播
        // },
        // autoplay:500,
        scrollbar: {
          // 滚动条
          el: ".swiper-scrollbar",
          hide: true, //滚动条是否自动隐藏。默认：false，不会自动隐藏。
          draggable: true, //该参数设置为true时允许拖动滚动条。
          snapOnRelease: true, //如果设置为false，释放滚动条时slide不会自动贴合。
          dragSize: 30, //设置滚动条指示的尺寸。默认'auto': 自动，或者设置num(px)。
        },
        loop: true,
        speed: 2500,
        slidesPerView: "auto",
        spaceBetween: 0,
        centeredSlides: true,
        watchSlidesProgress: true,
        zoom: true,
        // width: 106,
        on: {
          // setTranslate() {
          // let slides = this.slides
          // for (let i = 0; i < slides.length; i++) {
          //     let slide = slides.eq(i)
          //     let progress = slides[i].progress
          //     //slide.html(progress.toFixed(2)); 看清楚progress是怎么变化的
          //     slide.css({ 'opacity': '', 'background': '' }); slide.transform('');//清除样式
          //     slide[0].lastElementChild.style.display = 'none'
          //     slide[0].firstChild.src = require('@/assets/image/star/member-bg4.png')
          //     slide.transform('scale(' + (1 - Math.abs(progress) / 8) + ')');
          // }
          // },
          // setTransition: function (transition) {
          // for (var i = 0; i < this.slides.length; i++) {
          //     var slide = this.slides.eq(i)
          //     slide.transition(transition);
          // }
          // },
          click: () => {
            let swiper = this.$refs.mySwiper.$el.swiper;
            // console.log(swiper.clickedIndex); //当前点击的索引
            this.$router.push({
              path: "/detail/starDetail",
              query: {
                index: swiper.clickedIndex,
              },
            });
            // var sibling = e.target.parentElement.parentElement.parentNode.childNodes;

            // let flag = false
            // for (var i = 0; i < sibling.length; i++) {

            //     let progress = this.slides[i].progress

            //     // 判断点击是否是人物头像
            //     if (sibling[i].className && sibling[i].className?.indexOf('member__select') === -1) {
            //         return
            //     } else {
            //         //删除样式
            //         sibling[i].lastElementChild.style.display = 'none'
            //         sibling[i].firstChild.src = require('@/assets/image/star/member-bg4.png')
            //         if (sibling[i].style.transform === 'scale(1.5)') {
            //             sibling[i].style.transform = ('scale(' + (1 - Math.abs(progress) / 8) + ')');
            //             flag = true
            //         }
            //     }
            // }
            // // 更换图片

            // e.target.parentElement.parentElement.style.transition = '500ms'

            // if (!flag) e.target.parentElement.parentElement.style.transform = 'scale(1.5)';
            // if (!flag) e.target.parentElement.parentElement.lastElementChild.style.display = 'block';
            // if (!flag) e.target.parentElement.parentElement.firstChild.src = require('@/assets/image/star/member-select.png')
            // flag = false
          },
        },
      }
      }
  },
  methods: {
    handleClickItem(index) {
      console.log(index);
      //   this.$router.push({
      //     name: "starDetail",
      //   });
    },
  },
  data() {
    return {
      issSowBar: false,
            // 改变swiper样式时，自动初始化swiper
            observer: true,
            // 监测swiper父元素，如果有变化则初始化swiper
            observeParents: true,
    };
  },
};
</script>

<style lang="less" scoped>
.swiper-container {
  width: 100%;
  height: auto;
  padding-top: 50px;
  // margin-left: 10%;
  margin-right: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  // background: #fff;
  width: 212px;
  height: 212px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition-property: all;
}

.member__item--pic {
  z-index: 888;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  overflow: hidden;
  top: 15%;
  left: 15%;
  cursor: pointer;
  background-image: url('../assets/image/star/member-bg4.png');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.7);
  transition: all .3s;

  img{
    width: 70%;
    height: auto;
    border-radius: 50%;
  }
}

.member__select {
  width: 212px !important;
  height: 212px !important;
  margin-left: 37px;
  position: relative;
  transition: all .3s;

  &:hover {
    .member__item--pic {
      background-image: url("../assets/image/star/member-select.png");
      transform: scale(1.6);
    }
    .member-bar{
      display: block;
    }
  }
}
.swiper-slide-prev{
  // margin-left: 0;
  .member__item--pic {
  transform: scale(1);
  }
}
.swiper-slide-next{
  .member__item--pic {
  transform: scale(1);
  }
}
.swiper-slide-active{
  margin: 0 100px;
  .member__item--pic {
  transform: scale(1.3);
  }
}
.member-bar {
  position: absolute;
  width: 80px;
  height: 80px;
  bottom: -30px;
  right: 30%;
  display: none;
}

.member-info {
  position: absolute;
  height: 27px;
  bottom: -22px;
  left: 74px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .info-name {
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #272d38;
    line-height: 27px;
    height: 27px;
    background: #fff;
    padding: 0 8px;
  }

  .info-icon {
    width: 27px;
    height: 27px;
    margin-left: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  // right:;
}
</style>

<style lang="less">
.swiper-scrollbar {
  position: absolute;
  left: 10% !important;
  bottom: 100px !important;
  background-color: #404246 !important;
}
.swiper-scrollbar-drag {
  background: #8afff2 !important;
  width: 200px !important;
}
</style>