var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conact"},[_c('div',{staticClass:"conact_main"},[_c('img',{staticClass:"full-bg",attrs:{"src":require("../../assets/image/conact/conact_bg.png"),"alt":""}}),_vm._m(0),_vm._m(1),_c('div',{staticClass:"conact_middle"},[_c('div',{staticClass:"middle_01"},[_c('div',{staticClass:"address"},[_c('div',{staticClass:"address_item"},[_c('span',[_vm._v("公司：")]),_c('span',[_vm._v(_vm._s(_vm.info.companyName))])]),_c('div',{staticClass:"address_item"},[_c('span',[_vm._v("地址：")]),_c('span',[_vm._v(_vm._s(_vm.info.companyAddress))])]),_c('div',{staticClass:"address_item"},[_c('span',[_vm._v("联系人：")]),_c('span',[_vm._v(_vm._s(_vm.info.contactUser))])]),_c('div',{staticClass:"address_item"},[_c('span',[_vm._v("联系方式：")]),_c('span',[_vm._v(_vm._s(_vm.info.contactPhone))])]),_vm._m(2)]),_vm._m(3),_vm._m(4)]),_vm._m(5),_vm._m(6)]),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title_img"},[_c('img',{attrs:{"src":require("@/assets/image/conact/conact_title.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title_warp"},[_c('span',[_vm._v("联系我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"beian_wrap"},[_vm._v("备案号："),_c('a',{staticStyle:{"color":"white"},attrs:{"href":"https://beian.miit.gov.cn/"}},[_vm._v("沪ICP备2020038140号-2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code_1"},[_c('div',{staticClass:"code_img"},[_c('img',{attrs:{"src":require("../../assets/image/home/wechat_kefu.png"),"alt":""}})]),_c('span',[_vm._v("微信客服")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code_1"},[_c('div',{staticClass:"code_img"},[_c('img',{attrs:{"src":require("../../assets/image/home/wechat_gongzhonghao.png"),"alt":""}})]),_c('span',[_vm._v("微信公众号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"middle_02"},[_c('img',{attrs:{"src":require("@/assets/image/conact/conact_18.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"middle_03"},[_c('img',{attrs:{"src":require("@/assets/image/conact/conact_16.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('img',{attrs:{"src":require("@/assets/image/home/home_bottom.png"),"alt":""}})])
}]

export { render, staticRenderFns }